import React, { useEffect, useState } from 'react'
import './NewsPage.css'
import { useParams } from 'react-router-dom'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { news } from '../assets/content/news'
import { getLanguage } from '../components/helpers/helpers'


import News1 from '../components/newsItems/News1'
import News2 from '../components/newsItems/News2'
import News3 from '../components/newsItems/News3'
import News4 from '../components/newsItems/News4'
import News5 from '../components/newsItems/News5'

function NewsPage() {
  const { newsID } = useParams()

  const [newsComponent, setNewsComponent] = useState(null)

  const selectedLang = getLanguage()
  const newsItem = news.find(news => news.id == newsID)
  const newsItemName = `news${newsItem.id}`

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0)

    switch (newsItemName) {
      case "news1":
        setNewsComponent(<News1 />)
        break
      case "news2":
        setNewsComponent(<News2 />)
        break
      case "news3":
        setNewsComponent(<News3 />)
        break
      case "news4":
        setNewsComponent(<News4 />)
        break
      case "news5":
        setNewsComponent(<News5 />)
        break
    }
  }, [])

  return (
    <div className='news_page' id={`news-page`}>

      <Menu
        isLogoVisible={true}
        includeGoBack={true}
      />

      <div className='news_page_body'>
        <div className='main_image' >
          <div className='overlay'></div>
          {
            newsItem.main_image &&
            <img src={require(`../assets/images/news/${newsItem.main_image}`)} />
          }
        </div>

        <div className='content'>
          <h1>{newsItem.content.title[selectedLang]}</h1>
          <p>{newsItem.date} by {newsItem.by}</p>

          <div className='main_paragraph'>{newsItem.content.main_paragraph[selectedLang]}</div>

          {newsComponent}
        </div>


      </div>


      <Footer />
    </div>
  )
}

export default NewsPage
