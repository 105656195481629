import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './InvestPage.css'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { investPageContent } from '../assets/content/content'
import { getLanguage } from '../components/helpers/helpers'
import { IoMailOutline } from "react-icons/io5"
import { PiBuildingApartmentLight } from "react-icons/pi"
import { LiaChartBar } from "react-icons/lia"
import { BsCalendarEvent } from "react-icons/bs"
import { LiaHandshake } from "react-icons/lia"
import ContactForm from '../components/ContactForm'

function InvestPage() {
  const navigate = useNavigate()
  const selectedLang = getLanguage()
  const content = investPageContent[selectedLang]

  const pageTitleRef = useRef(null)
  const highlightTimer = useRef(null)
  const pageTitleWords = content.pageTitle.split(' ')

  const contactFormRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)

    const pageTitle = pageTitleRef.current
    const highlight = pageTitle.querySelector('.highlight')

    highlightTimer.current = setTimeout(() => {
      highlight.classList.add('animated')
    }, 750)

    return () => {
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }
  }, [])

  const benefitIcons = [
    <IoMailOutline />,
    <PiBuildingApartmentLight />,
    <LiaChartBar />,
    <BsCalendarEvent />,
    <LiaHandshake />
  ]

  return (
    <div className='invest__page'>
      <Menu
        menuLogo={require('../assets/logo/logo_vitruvi_transparant_color.png')}
        includeGoBack={true}
      />

      <div className='invest_body'>
        <h1 className='page_title' ref={pageTitleRef}>
          {pageTitleWords.slice(0, -1).map((word, index) => (
            <div key={index} className='page_title__word visible'>{word}</div>
          ))}
          <div className='page_title__word visible highlight'>{pageTitleWords[pageTitleWords.length - 1]}</div>
        </h1>

        <div className='invest_content'>
          <div className='opening_paragraph'>
            <div className='subtitle'>{content.subtitle}</div>
            <div className='content'>{content.openingParagraph}</div>
          </div>

          <ul className='benefits_list'>
            {content.benefits.map((benefit, index) => (
              <li key={index} className='benefit_item'>
                <span className='benefit_icon'>{benefitIcons[index]}</span>
                <span className='benefit_title'>{benefit.title}</span>
                <span className='benefit_text'>{benefit.text}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className='contact_form' ref={contactFormRef}>
          <h1 className='page_sub_title'>{content.formTitle}</h1>
          <ContactForm tag={{"type": "investorsClub", "name": "Investors club", "id": null }} />
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default InvestPage