import React, { useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './AboutPage.css'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { aboutPageContent } from '../assets/content/content'
import { getLanguage } from '../components/helpers/helpers'

function AboutPage() {
  const selectedLang = getLanguage()
  const content = aboutPageContent[selectedLang]

  const pageTitleRef = useRef(null)
  const highlightTimer = useRef(null)
  const pageTitleWords = content.pageTitle.split(' ')

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0)

    const pageTitle = pageTitleRef.current
    const highlight = pageTitle.querySelector('.highlight')

    highlightTimer.current = setTimeout(() => {
      highlight.classList.add('animated')
    }, 750)

    return () => {
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }
  }, [])

  return (
    <div className='about__page'>
      <Menu
        menuLogo={require('../assets/logo/logo_vitruvi_transparant_color.png')}
        includeGoBack={true}
      />

      <div className='about_body'>
        <h1 className='page_title' ref={pageTitleRef}>
          {pageTitleWords.slice(0, -1).map((word, index) => (
            <div key={index} className='page_title__word visible'>{word}</div>
          ))}
          <div className='page_title__word visible highlight'>{pageTitleWords[pageTitleWords.length - 1]}</div>
        </h1>

        <div className='about_content'>
          {content.blocks.map((block, index) => (
            <div key={index} className='about_content__block'>
              <h2 className='block_title'>{block.title}</h2>
              <div className='block_paragraphs'>
                {block.paragraphs.map((paragraph, pIndex) => (
                  <p key={pIndex}>{paragraph}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default AboutPage