import React, { useEffect, useState, useRef } from 'react'
import './ProjectPage.css'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdClose } from "react-icons/io"
import { FaRightLong, FaLeftLong } from "react-icons/fa6"
import Menu from '../components/Menu'
import { formatUrl, getLanguage } from '../components/helpers/helpers'
import Map from '../components/Map'
import Footer from '../components/Footer'
import { projectPageContent } from '../assets/content/content'
import { useSwipeable } from 'react-swipeable'
import ContactForm from '../components/ContactForm'

function ProjectPage({ projects }) {
  const { projectID } = useParams()
  const project = projects.find(project => project.id == projectID)

  const [imageLoaded, setImageLoaded] = useState(new Array(project?.images.length).fill(false))
  const [imagePopupIsActive, setImagePopupIsActive] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  const selectedLang = getLanguage()
  const content = projectPageContent[selectedLang]
  const contactFormRef = useRef(null)

  const navigate = useNavigate()

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (imagePopupIsActive) {
      document.body.style.overflow = 'hidden'  // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [imagePopupIsActive])

  // Function to dynamically determine the grid span classes
  const getGridItemClass = (index) => {
    const pattern = [
      'grid_item grid_item_span_2x2',
      'grid_item grid_item_span_1x1',
      'grid_item grid_item_span_1x2',
      'grid_item grid_item_span_2x1',
      'grid_item grid_item_span_1x1',
      'grid_item grid_item_span_2x1'
    ]
    return pattern[index % 6]
  }

  const handleImageLoad = (index) => {
    setImageLoaded(prevState => {
      const newImageLoaded = [...prevState]
      newImageLoaded[index] = true
      return newImageLoaded
    })
  }

  const handleOnclickImage = (imageIndex) => {
    setSelectedImageIndex(imageIndex)
    setImagePopupIsActive(true)
  }

  const handleOnCloseImagePopup = () => {
    setImagePopupIsActive(false)
    setSelectedImageIndex(null)
  }

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className='project_page' id={`project-page-${project?.project_name}`}>

      <Menu
        isLogoVisible={!imagePopupIsActive}
        includeGoBack={true}
      />

      <div className={`image_popup ${imagePopupIsActive ? "active" : ""}`}>
        <div className='closing_icon' onClick={handleOnCloseImagePopup}><IoMdClose /></div>

        <FaLeftLong className={`navigation left ${selectedImageIndex == 0 ? "hidden" : ""}`} onClick={() => setSelectedImageIndex(selectedImageIndex - 1)} />
        <FaRightLong className={`navigation right ${selectedImageIndex == project?.images.length - 1 ? "hidden" : ""}`} onClick={() => setSelectedImageIndex(selectedImageIndex + 1)} />

        <div className='image_popup_content'>
          <img src={project?.images[selectedImageIndex]} alt={`Project ${selectedImageIndex}`} />
        </div>
      </div>

      <div className='image_container'>
        <img className='main_image' src={project?.main_image} alt={project?.project_name} />
      </div>

      <div className='project_page_content_container'>
        <div className='project_content_container'>
          <div className='project_title_description'>
            <h1>{project?.project_name}</h1>
            <div dangerouslySetInnerHTML={{ __html: project?.description_short[selectedLang] }} />
            <button className='content_button' onClick={scrollToContactForm}>Contacteer ons</button>
          </div>

          <div className='project_details'>
            {project?.location && (
              <div className='project_detail'>
                <p className='mini_title'>{content.location}:</p>
                <p className='detail_content'>{project.location}</p>
              </div>
            )}
            {project?.programs && project.programs.length > 0 && (
              <div className='project_detail'>
                <p className='mini_title'>{content.units}:</p>
                {project.programs.map((program, index) => (
                  <p className='detail_content strong' key={index}>
                    <p>{program[selectedLang]}</p>
                    {index < project.programs.length - 1 && <p>|</p>}
                  </p>
                ))}
              </div>
            )}
            {project?.unique_aspect && project.unique_aspect[selectedLang] && (
              <div className='project_detail'>
                <p className='mini_title'>{content.unique}:</p>
                <p className='detail_content'>{project.unique_aspect[selectedLang]}</p>
              </div>
            )}
          </div>
        </div>

        <div className='content_body'>
          <div className='images_grid'>
            {
              project?.images.slice(0, 4).map((image, index) => {
                return (
                  <div key={index} className={getGridItemClass(index)} onClick={() => handleOnclickImage(index)}>
                    {!imageLoaded[index] && <div className="image_skeleton"></div>}
                    <img
                      src={image}
                      alt={`Project ${index}`}
                      onLoad={() => handleImageLoad(index)}
                      className={imageLoaded[index] ? 'loaded' : 'loading'}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className='project_content_container'>
            <div className='project_title_description'>
              <h1>{project?.project_name}</h1>
              <div dangerouslySetInnerHTML={{ __html: project?.description[selectedLang] }} />
            </div>

            <div className='project_details'>
              {project?.website && (
                <div className='project_detail'>
                  <p className='mini_title'>{content.website}:</p>
                  <a href={formatUrl(project.website)} className='detail_content' target='_blank' rel="noopener noreferrer">
                    {project.website}
                  </a>
                </div>
              )}
              {project?.status && (
                <div className='project_detail'>
                  <p className='mini_title'>{content.status}:</p>
                  <p className='detail_content strong'>{project.status[selectedLang]}</p>
                </div>
              )}
              {project?.architect && (
                <div className='project_detail'>
                  <p className='mini_title'>{content.architect}:</p>
                  <p className='detail_content'>{project.architect}</p>
                </div>
              )}

              <button className='content_button' onClick={scrollToContactForm}>Contacteer ons</button>
            </div>
          </div>

          <div className='images_grid'>
            {
              project?.images.slice(4).map((image, index) => {
                return (
                  <div key={index} className={getGridItemClass(index)} onClick={() => handleOnclickImage(index)}>
                    {!imageLoaded[index] && <div className="image_skeleton"></div>}
                    <img
                      src={image}
                      alt={`Project ${index}`}
                      onLoad={() => handleImageLoad(index)}
                      className={imageLoaded[index] ? 'loaded' : 'loading'}
                    />
                  </div>
                )
              })
            }
          </div>

          <Map address={project?.location} />

          <div className='contact_form' ref={contactFormRef}>
            <h1 className='page_sub_title'>Contacteer ons voor<br /> meer info over dit project</h1>
            <ContactForm tag={{ "type": "project", "name": project?.project_name, "id": project?.id }} />
          </div>

        </div>
      </div>

      <Footer />
    </div >
  )
}

export default ProjectPage
