import React from 'react'
import './NewsItem.css'

function News1() {
  return (
    <div className='news_item_body'>
      <img className='img_full_width' src={require(`../../assets/images/news/news1.jpg`)} />
    </div>
  )
}

export default News1
