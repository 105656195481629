export const formatUrl = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url
}

export const getLocationPart = (location) => {
  const parts = location.split(',');
  return parts.length > 1 ? parts[1].trim() : location;
}

export const getPath = (path) => {
  try {
    return require(path)
  } catch (err) {
    console.error(`Path "${path}" not found`, err)
    return null
  }
}

export const getLanguage = () => {
  const storedLang = localStorage.getItem('lang')
  const validLanguages = ["nl", "fr", "en"]
  return validLanguages.includes(storedLang) ? storedLang : 'en'
}

export const setLanguage = (lang) => {
  const validLanguages = ["nl", "fr", "en"]
  if (validLanguages.includes(lang)) {
    localStorage.setItem('lang', lang)
  }
}

export const getDefaultLanguage = () => {
  // Get the browser's primary language setting
  const browserLanguage = navigator.language || navigator.languages[0]
  
  const languageMap = {
    'nl': 'nl',
    'fr': 'fr',
    'en': 'en',
    'en-US': 'en',
    'en-GB': 'en',
    // Add more mappings as needed
  }

  return languageMap[browserLanguage] || 'en'
}
