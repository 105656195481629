import React, { useEffect, useState } from 'react'
import { contactFormContent } from '../assets/content/content.js'
import { getLanguage } from './helpers/helpers.js'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from 'react-toastify'
import httpClient from './helpers/httpClient.js'
import './ContactForm.css'
import { IoCheckmarkSharp } from "react-icons/io5"

function ContactForm({ tag }) {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [messageSend, setMessageSend] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    telNumber: '',
    email: '',
    message: '',
    accepted: false,
  })

  const selectedLang = getLanguage()
  const content = contactFormContent[selectedLang]

  useEffect(() => {
    setFormData({
      ...formData,
      "tag": tag,
    })
  }, [tag])

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return re.test(String(email).toLowerCase())
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingSubmit(true)

    if (!formData.accepted) {
      toast.error(content.acceptDisclaimer)
      setLoadingSubmit(false)
      return
    }

    if (!formData.name || !formData.lastName || !formData.email || !formData.message) {
      toast.error(content.fillAllFields)
      setLoadingSubmit(false)
      return
    }

    if (!validateEmail(formData.email)) {
      toast.error(content.validEmail)
      setLoadingSubmit(false)
      return
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/website/new-contact", formData, { headers: { 'Content-Type': 'application/json' } })

      if (response.status === 200) {
        setMessageSend(true)
        setFormData({
          name: '',
          lastName: '',
          telNumber: '',
          email: '',
          message: ''
        })
        console.log(response.data)
      } else {
        console.log(response.data)
      }
    } catch (error) {
      console.error("There was an error submitting the form", error)
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} id='contact-form'>
      <div className='input_combo'>
        <div className='input_wrapper'>
          <input className='input_field text' type='text' name='name' value={formData.name} onChange={handleChange} placeholder={content.voornaam} />
        </div>
        <div className='input_wrapper'>
          <input className='input_field text' type='text' name='lastName' value={formData.lastName} onChange={handleChange} placeholder={content.achternaam} />
        </div>
      </div>

      <div className='input_wrapper'>
        <input className='input_field text' type='text' name='telNumber' value={formData.telNumber} onChange={handleChange} placeholder={content.telefoon} />
      </div>

      <div className='input_wrapper'>
        <input className='input_field text' type='text' name='email' value={formData.email} onChange={handleChange} placeholder={content.email} />
      </div>

      <div className='input_wrapper textarea_wrapper'>
        <textarea className='input_field text_area' type='message' name='message' value={formData.message} onChange={handleChange} placeholder={content.bericht} />
      </div>

      <div
        className='checkmark_container'
        onClick={() => setFormData(prevState => ({ ...prevState, accepted: !prevState.accepted }))}
      >
        <div className={`checkmark_box ${formData.accepted ? "selected" : ""}`}><IoCheckmarkSharp /></div>
        <div className='checkmark_text'>Ik ga ermee akkoord dat mijn gegevens worden verwerkt in overeenstemming met het privacybeleid.</div>
      </div>

      <button className='form_submit_button' type='submit' disabled={messageSend}>
        {
          loadingSubmit ?
            <CircularProgress color="inherit" size={"1rem"} /> :
            <>
              {messageSend ? content.berichtVerzonden : content.verzend}
            </>
        }
      </button>
    </form>
  )
}

export default ContactForm