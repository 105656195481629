import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './NewsOverviewPage.css'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { news } from '../assets/content/news'
import { newsOverviewPageContent } from '../assets/content/content'
import { getLanguage } from '../components/helpers/helpers'

function NewsOverviewPage() {
  const navigate = useNavigate()
  const selectedLang = getLanguage()
  const content = newsOverviewPageContent[selectedLang]
  const featuredNews = news.find(newsItem => newsItem.is_featured) || news[news.length - 1]

  const pageTitleRef = useRef(null)
  const highlightTimer = useRef(null)
  const pageTitleWords = content.pageTitle.split(' ')

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0)

    const pageTitle = pageTitleRef.current
    const highlight = pageTitle.querySelector('.highlight')

    highlightTimer.current = setTimeout(() => {
      highlight.classList.add('animated')
    }, 750)

    return () => {
      if (highlightTimer.current) {
        clearTimeout(highlightTimer.current)
      }
    }
  }, [])

  const onHoverNewsItem = (event, type) => {
    const thisNewsItem = event.currentTarget
    const image = thisNewsItem.querySelector('.news_image')
    const details = thisNewsItem.querySelector('.news_details')

    if (type === 'enter') {
      image.classList.add('hovered')
      details.classList.add('hovered')
    }
    if (type === 'leave') {
      image.classList.remove('hovered')
      details.classList.remove('hovered')
    }
  }

  return (
    <div className='news_overview__page'>
      <Menu
        menuLogo={require('../assets/logo/logo_vitruvi_transparant_color.png')}
        includeGoBack={true}
      />

      <div className='news_overview_body'>
      <h1 className='page_title' ref={pageTitleRef}>
          {pageTitleWords.slice(0, -1).map((word, index) => (
            <div key={index} className='page_title__word visible'>{word}</div>
          ))}
          <div className='page_title__word visible highlight'>{pageTitleWords[pageTitleWords.length - 1]}</div>
        </h1>

        <div className='news_item featured'
          onMouseEnter={(e) => onHoverNewsItem(e, 'enter')}
          onMouseLeave={(e) => onHoverNewsItem(e, 'leave')}
          onClick={() => navigate(`/news/${featuredNews.id}`)}
        >
          <div className='news_details'>
            <h3>{featuredNews.content.title[selectedLang]}</h3>
            <p>{`${featuredNews.date} ${content.by} ${featuredNews.by}`}</p>
          </div>
          <img className='news_image' src={require(`../assets/images/news/${featuredNews.main_image}`)} alt='Featured News' />
        </div>

        <div className='news_grid'>
          {
            news
              .slice().reverse()
              .filter(news => news.id !== featuredNews.id)
              .map(news => (
                <div className='news_item'
                  key={news.id}
                  onMouseEnter={(e) => onHoverNewsItem(e, 'enter')}
                  onMouseLeave={(e) => onHoverNewsItem(e, 'leave')}
                  onClick={() => navigate(`/news/${news.id}`)}
                >
                  <div className='news_details'>
                    <h3>{news.content.title[selectedLang]}</h3>
                    <p>{`${news.date} ${content.by} ${news.by}`}</p>
                  </div>
                  {
                    news.main_image ?
                      <img className='news_image' src={require(`../assets/images/news/${news.main_image}`)} alt={news.title} />
                      :
                      <div className='padding'>
                        <img className='news_image ' src={require(`../assets/logo/logo_vitruvi_transparant_color.png`)} alt='Vitruvi Logo' />
                      </div>
                  }
                </div>
              ))
          }
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default NewsOverviewPage
