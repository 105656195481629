import React from 'react'
import './NewsItem.css'
import { news5Content } from '../../assets/content/content'
import { getLanguage } from '../helpers/helpers'

function News5() {
  const selectedLang = getLanguage()
  const content = news5Content[selectedLang]

  return (
    <div className='news_item_body'>
      <img className='img_full_width' src={require(`../../assets/images/news/news5.1.jpg`)} alt="News" />

      <div className='paragraph'>
        {content.paragraph1}
      </div>

      <div className='quote right'>
        {content.quote}
      </div>

      <div className='paragraph'>
        {content.paragraph2}
      </div>

      <div className='paragraph'>
        {content.paragraph3}
      </div>

      <img className='img_full_width' src={require(`../../assets/images/news/news5.2.jpg`)} alt="News" />
    </div>
  )
}

export default News5

